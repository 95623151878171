<template>
  <div class="wrapper" id="authority">
    <ctheader
      :titles="pageInfo.titles"
      :back-btn="pageInfo.backBtn"
      :back-path="pageInfo.backPath"
    />
    <div class="content" ref="authority">
      <div class="table-head">
        <div class="left">
          <input
            type="text"
            class="w150 search"
            placeholder="用户名"
            @keypress.enter="getFirstPageData"
            v-model="search.username"
            style="border:1px solid #257873"
          />
          <i class="sdnm-icon sdnm-search" @click="getFirstPageData"></i>
          <input
            type="tel"
            class="w150 search"
            placeholder="手机号"
            @keypress.enter="getFirstPageData"
            v-model="search.phone"
            style="border:1px solid #257873"
          />
          <i class="sdnm-icon sdnm-search" @click="getFirstPageData"></i>
        </div>
        <div class="right">
          <button
            class="btn primary"
            @click="createUser"
            style="background:#257873;border:1px solid #257873"
          >
            创建用户
          </button>
        </div>
      </div>
      <div class="table-scroll">
        <table class="table" width="100%" :style="{ 'min-width': '800px' }">
          <thead>
            <tr>
              <th class="pw15">用户编号</th>
              <th class="pw25">用户名</th>
              <th class="pw25">手机号</th>
              <th class="pw15">最后登录时间</th>
              <th class="pw20">操作</th>
            </tr>
          </thead>
          <tbody :style="{ height: protbHeight + 'px' }">
            <tr v-for="row in proTbody">
              <td class="pw15 ellipsis">{{ row.id }}</td>
              <th class="pw25 ellipsis">{{ row.username }}</th>
              <th class="pw25 ellipsis">{{ row.mobile }}</th>
              <th class="pw15 ellipsis">{{ row.last_login_time }}</th>
              <td class="operation">
                <a @click="reset(row)" style="color:#257873">重置密码</a>
                <a @click="del(row)" style="color:#257873">删除</a>
              </td>
            </tr>
            <tr v-if="proTbody.length === 0">
              <td class="empty" colspan="9">没有相关记录</td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :start="search.start"
        :total="search.total"
        :page-length="search.length"
        :pageCallback="page"
      ></pagination>
    </div>
    <layers
      :show="layerNew.show"
      title="创建用户"
      :width="450"
      :btnLoadDisabled="layerNew.disabled"
      :saveCallBack="layerNew.saveCallBack"
      :closeCallBack="layerNew.closeCallBack"
    >
      <div class="form">
        <div class="field is-12">
          <label class="w80 tar">手机号</label>
          <input
            type="text"
            v-model="layerNew.mobile"
            class="w300"
            placeholder=""
            maxlength="11"
            @input="checkNum(layerNew.mobile)"
          />
        </div>
        <div class="field">
          <label class="w80 tar">姓名</label>
          <input
            type="text"
            v-model="layerNew.username"
            class="w300"
            placeholder=""
            maxlength="20"
          />
        </div>
      </div>
    </layers>
    <layers
      :show="layerReset.show"
      title="重置密码"
      :width="500"
      :saveCallBack="layerReset.saveCallBack"
      :btnLoadDisabled="layerReset.disabled"
      :closeCallBack="layerReset.closeCallBack"
    >
      <div class="form">
        <div class="field">
          <label>新密码</label>
          <input
            type="password"
            class="w300"
            v-model="layerReset.password"
            placeholder=""
            minlength="8"
            maxlength="16"
          />
        </div>
      </div>
    </layers>
    <mimesis
      :show="confirm.showConfirm"
      :closeCallBack="closeCallBack"
      :saveCallBack="confirm.confirmCallback"
      :showIcon="true"
      :saveBtn="confirm.confirmBtnText"
    >
      <div>{{ confirm.confirmInfoText }}</div>
    </mimesis>
  </div>
</template>
<script>

// import ctheader from '@@/ctheader';
import { tableResize, apiPost } from "../../modules/utils";
import APIs from "../../modules/APIs";
// import pagination from '@@/pagination';
// import layers from '@@/layers';
// import mimesis from '@@/mimesis';
import pagination from "../../components/pagination";
import ctheader from "../../components/ctheader";
import mimesis from "../../components/mimesis";
import layers from "../../components/layers";
import { mapActions } from "vuex";
export default {
  name: "authority",
  components: { ctheader, pagination, layers, mimesis },
  data() {
    return {
      pageInfo: {
        titles: [],
        backBtn: false,
      },
      search: {
        username: "", // 用户名
        phone: "", // 手机号
        start: 0,
        total: 0,
        length: 10,
      },
      protbHeight: 0, // 表格高度(px)
      proTbody: [], // 表格数据
      btnLoadDisabled: false, // layer的关闭
      confirm: {
        showConfirm: false,
        confirmInfoText: "",
        confirmCallback: () => {},
      },
      layerNew: {
        show: false,
        disabled: false,
        mobile: "",
        username: "",
        saveCallBack: () => {
          if (!this.layerNew.mobile) {
            this.setAlert({
              msg: "请输入手机号",
            });
            return;
          }
          if (!this.layerNew.username) {
            this.setAlert({
              msg: "请输入姓名",
            });
            return;
          }
          let $this = this;
          $this.layerNew.disabled = true;
          var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;

          if (!myreg.test(this.layerNew.mobile)) {
            $this.confirm.showConfirm = false;
            $this.layerNew.disabled = false;
            this.setAlert({
              msg: "请输入正确的手机号",
            });
            return;
          } else {
            apiPost({
              url: APIs.AUTHORITY,
              data: {
                action: "add.user",
                mobile: this.layerNew.mobile,
                username: this.layerNew.username,
              },
              success(res) {
                $this.layerNew.disabled = false;
                if (res.code === "0") {
                  $this.setAlert({
                    msg: res.msg,
                    type: true,
                  });
                  $this.search.start = 0;
                  $this.getData();
                  $this.layerNew.show = false;
                } else {
                  $this.setAlert({
                    msg: res.msg,
                  });
                }
              },
              error(res) {
                $this.layerNew.disabled = false;
                $this.setAlert({
                  msg: "创建用户失败",
                });
              },
            });
          }
        },
        closeCallBack: () => {
          this.layerNew.show = false;
        },
      },
      layerReset: {
        show: false,
        disabled: false,
        password: "",
        row: {},
        closeCallBack: () => {
          this.layerReset.show = false;
        },
        saveCallBack: () => {
          if (!this.layerReset.row.id) {
            // 没有用户id
            return;
          }
          if (!this.layerReset.password) {
            this.setAlert({
              msg: "请输入新密码",
            });
            return;
          }
          if (this.layerReset.password.length < 8) {
            this.setAlert({ msg: "密码位数必须大于等于8位" }, { type: false });
            return;
          }
          let $this = this;
          $this.layerReset.disabled = true;
          apiPost({
            url: APIs.AUTHORITY,
            data: {
              action: "user.edit.password",
              user_id: this.layerReset.row.id,
              password: this.layerReset.password,
            },
            success(res) {
              $this.layerReset.disabled = false;
              if (res.code === "0") {
                $this.setAlert({
                  msg: res.msg,
                  type: true,
                });
                $this.layerReset.show = false;
              } else {
                $this.setAlert({
                  msg: res.msg,
                });
              }
            },
            error(res) {
              $this.layerReset.disabled = false;
              $this.setAlert({
                msg: "重置密码失败",
              });
            },
          });
        },
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.pageInfo.titles = to.meta.title;

      vm.getData();
      vm.tResize();
      window.addEventListener("resize", vm.tResize, false);
    });
  },
  methods: {
    ...mapActions(["setAlert"]),
    checkNum(val) {
      let reg = /(?:1[0-9]*)/;
      let result = reg.exec(val);
      this.layerNew.mobile = result !== null ? result[0] : "";
    },
    /**
     * 翻页
     * @author  Sheng Jie <yeonkitt@gmail.com>
     * @date    2021-01-12
     */
    page(index) {
      this.search.start = index;
      this.getData();
    },
    /**
     * 表格的重置密码按钮
     * @author  Sheng Jie <yeonkitt@gmail.com>
     * @date    2021-01-10
     * @param   <Object>    row 列表单项数据
     */
    reset(row) {
      this.layerReset.password = "";
      this.layerReset.row = row;
      this.layerReset.show = true;
    },
    /**
     * 表格的删除按钮
     * @author  Sheng Jie <yeonkitt@gmail.com>
     * @date    2021-01-10
     * @param   <Object>    row 列表单项数据
     */
    del(row) {
      let $this = this;
      this.confirm.confirmCallback = function() {
        apiPost({
          url: APIs.AUTHORITY,
          data: {
            action: "delete.user",
            user_id: row.id,
          },
          success(res) {
            $this.confirm.showConfirm = false;
            if (res.code === "0") {
              $this.setAlert({
                msg: res.msg,
                type: true,
              });
              $this.search.start = 0;
              $this.getData();
            } else {
              $this.setAlert({
                msg: res.msg,
              });
            }
          },
          error(res) {
            $this.confirm.showConfirm = false;
            $this.setAlert({
              msg: "操作失败",
            });
          },
        });
        // TODO
      };
      this.confirm.confirmInfoText = "是否确认删除?";
      this.confirm.showConfirm = true;
    },
    /**
     * 确认取消回调
     * @author  Sheng Jie <yeonkitt@gmail.com>
     * @date    2021-12-07
     */
    closeCallBack() {
      this.confirm.showConfirm = false;
    },
    /**
     * 重新获取第一页数据
     * @author  Sheng Jie <yeonkitt@gmail.com>
     * @date    2021-01-12
     */
    getFirstPageData() {
      this.page(0);
    },
    /**
     * 列表获取数据
     * @author  Sheng Jie <yeonkitt@gmail.com>
     * @date    2021-01-09
     */
    getData() {
      let $this = this;
      apiPost({
        url: APIs.AUTHORITY,
        data: {
          action: "user.list",
          start: this.search.start,
          length: this.search.length,
          username: this.search.username,
          phone: this.search.phone,
        },
        success(res) {
          if (res.code === "0") {
            $this.proTbody = res.data;
            $this.search.total = res.total;
          } else {
          }
          $this.tResize();
        },
        error(res) {
          $this.setAlert({
            msg: "获取数据失败",
          });
        },
      });
    },
    /**
     * 方法
     * @author  Sheng Jie <yeonkitt@gmail.com>
     * @date    2021-01-09
     */
    createUser() {
      this.layerNew.mobile = "";
      this.layerNew.username = "";
      this.layerNew.password = "";
      this.layerNew.show = true;
      this.errors.clear();
    },
    /**
     * 商品表格的自适应
     * @author wurongjian <wurongjian@rongyi.com>
     * @date   2021-09-29
     */
    tResize() {
      this.protbHeight = tableResize(
        this.$refs.authority.clientHeight,
        250,
        this.proTbody.length
      );
    },
  },
};
</script>
<style lang="less">
@import url("../../less/common.less");
#authority {
  .flex1 {
    flex: 1;
  }
  // .table-head{
  // 	display: flex;
  // 	align-items: center;
  // 	input:first-child{
  // 		.mgr(10px);
  // 	}
  // 	.sdnm-search{
  // 		.mgl(10px);
  // 	}
  // }
  .form {
    padding: 20px 0;
    .control {
      .fl-rt();
    }
  }
  .field {
    .tar {
      text-align: right;
    }
  }
  .m-mimesis {
    z-index: 1002;
  }
}
</style>
